import {post} from "./request";

export function error_message(message) {
    const button = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-primary w-xs mb-1"
        },
        buttonsStyling: false
    });
    button.fire({
        html: '<div class="mt-3">' +
            '<lord-icon src="https://cdn.lordicon.com/tdrtiskw.json" trigger="loop" colors="primary:#f06548,secondary:#f7b84b" style="width:120px;height:120px"></lord-icon>' +
            '<div class="mt-4 pt-2 fs-15">' +
            '<h4>오류메시지 !</h4>' +
            '<p class="text-muted mx-4 mb-0">'+message+'</p>' +
            '</div>' +
            '</div>',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: '확인',
        buttonsStyling: false,
        showCloseButton: true
    })
}

export function success_message(message) {
    const button = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-primary w-xs mb-1"
        },
        buttonsStyling: false
    });
    button.fire({
        html: '<div class="mt-3">' +
            '<lord-icon src="https://cdn.lordicon.com/lupuorrc.json" trigger="loop" colors="primary:#0ab39c,secondary:#405189" style="width:120px;height:120px"></lord-icon>' +
            '<div class="mt-4 pt-2 fs-15">' +
            '<h4>Well done !</h4>' +
            '<p class="text-muted mx-4 mb-0">'+message+'</p>' +
            '</div>' +
            '</div>',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: '확인',
        buttonsStyling: false,
        showCloseButton: true
    })
}

export function delete_message(type, enc) {
    let response = {};
    const button = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-danger w-xs me-2 mb-1",
            cancelButton: "btn btn-primary w-xs  mb-1"
        },
        buttonsStyling: false
    });
    button.fire({
        html: '<div class="mt-3">' +
            '<lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#f7b84b,secondary:#f06548" style="width:100px;height:100px"></lord-icon>' +
            '<div class="mt-4 pt-2 fs-15 mx-5">' +
            '<h4>삭제 하시겠습니까?</h4>' +
            '<p class="text-muted mx-4 mb-0">삭제시에는 복구가 불가능합니다. 주의해 주세요.!!</p>' +
            '</div>' +
            '</div>',
        showCancelButton: true,
        confirmButtonText: '삭제!',
        cancelButtonText: '아니오',
        showCloseButton: true
    }).then((result) => {
        if (result.isConfirmed) {
            const url = '/api/proc/delete';
            let data = { "type":type, "enc":enc}
            let response = post(url, data, "json");
            response = JSON.parse(response);
            if (response.code === "OK") {
                success_message("처리되었습니다.!!")
                setTimeout(function () {
                    location.reload();
                }, 1000);
            }
        }
    });

    return response;

}

export function comma(str) {
    str = String(str);
    str = str.replace(/[^0-9]/g,"");
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
}

export function toNum(str) {
    str = str.replace(/[^0-9]/g,"");
    return parseInt(str);
}

export default {
    error_message,
    success_message,
    delete_message,
    comma,
    toNum
}
