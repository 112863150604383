import cookie from "./module/cookie";
import { post } from "./module/request"
import { error_message } from "./module/common";

let getCookie = cookie.getCookie("user_id"),
    user_id = document.getElementById('user_id'),
    remember_me = document.getElementById('remember_me'),
    loginFm = document.getElementById("login_fm")

user_id.value = getCookie;
if(user_id.value !== ""){
    remember_me.checked = true;
}
remember_me.addEventListener("change", (e) => {
    if (e.target.checked) {
        cookie.setCookie("user_id", user_id.value, 30);
    } else {
        cookie.deleteCookie("user_id");
    }
});

user_id.addEventListener("keyup", function() {
    if(remember_me.checked){
        cookie.setCookie("user_id", user_id.value, 30);
    }
});

loginFm.addEventListener('submit', function (e) {
    const url = "/api/auth/login";
    if (loginFm.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        console.log(4)
    } else {
        e.preventDefault();
        let data = new FormData(loginFm);
        let response = post(url, data);
        response = JSON.parse(response);
        if (response.code === "OK") location.href = "/dashboard"
        else error_message(response.message)
    }

    loginFm.classList.add('was-validated');
}, false);







